<template>
  <admin-base ref="base">
    <div slot="title">
      {{ !this.$route.params.id ? 'Create' : 'Update' }}  company
    </div>
    <div class="card">
      <div class="card-body">
        <b-form @submit.prevent @submit="saveData">
          <b-row>
            <b-col md="12">
              <b-form-group label="Ativo">
                <b-form-checkbox v-model="data.enabled" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Nome">
                <b-form-input v-model="data.name" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Descrição">
                <b-form-input v-model="data.description" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="E-mail">
                <b-form-input v-model="data.email" type="email" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Telefone">
                <b-form-input v-model="data.phone" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Telemóvel">
                <b-form-input v-model="data.mobile" />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="goBack"
              >
                Cancel
              </b-button>
              <b-button
                v-if="this.$route.params.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                class="ml-1"
                @click="onDelete"
              >
                Delete
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </admin-base>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import * as bootstrapVue from 'bootstrap-vue'
import AdminBase from '@/views/admin/AdminBase.vue'

export default {
  components: {
    AdminBase,
    BRow: bootstrapVue.BRow,
    BCol: bootstrapVue.BCol,
    BFormGroup: bootstrapVue.BFormGroup,
    BFormInput: bootstrapVue.BFormInput,
    BFormCheckbox: bootstrapVue.BFormCheckbox,
    BForm: bootstrapVue.BForm,
    BButton: bootstrapVue.BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
    }
  },
  mounted() {
    this.loadData()
  },
  created() {
  },
  methods: {
    loadData() {
      if (!this.$route.params.id) {
        this.data = { enabled: true }
      } else {
        this.$refs.base.showLoading()
        axios.get(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/companies/${this.$route.params.id}/show`)
        .then(response => {
          this.data = response.data.company
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToast(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          this.$refs.base.hideLoading()
        })
      }
    },
    saveData() {
      this.errored = false
      this.$refs.base.showLoading()
      if (!this.$route.params.id) {
        axios.post(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/companies/create`, this.data)
        .then(() => {
          this.$refs.base.showToast('Empresa criada com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      } else {
        axios.put(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/companies/${this.$route.params.id}/update`, this.data)
        .then(() => {
          this.$refs.base.showToast('Empresa atualizada com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      }
    },
    goBack() {
      this.$refs.base.goBack()
    },
    onDelete() {
      this.errored = false
      if (window.confirm('Deseja realmente remover a empresa?')) {
        axios.delete(`${process.env.VUE_APP_CORE_ENDPOINT}/admin/companies/${this.$route.params.id}`)
        .then(() => {
          this.$refs.base.showToast('Empresa deletada com sucesso')
        })
        .catch(error => {
          this.$refs.base.checkError(error)
          this.$refs.base.showToastError(error.response.data.error)
          this.errored = true
        })
        .finally(() => {
          if (!this.errored) this.goBack()
          this.$refs.base.hideLoading()
        })
      }
    },
  },
}
</script>
